var config = {};

config.server = {
    url: 'https://incrementum.softwarefactory.ai',
    //url: 'http://localhost',
    webClientPort: 7001,
    webServicesPort: 7002 }

// config.server = {
//     url: 'http://45.34.164.42',
//     webClientPort: 3000,
//     webServicesPort: 6376 }

// config.server = {
//     url: 'http://192.168.43.31',
//     webClientPort: 3000,
//     webServicesPort: 6376 }

//config.apiKey = 'sk-svcacct-m0Li-CDGB9GBPqUQcRLMayopei6tbAs2uXmHIwgtRO3CQcKJJNcIIi--ET3BlbkFJoPVoMVpEo2rY3j6bZfp67zOcdDLCy_qqbdQ9f3PVsKOmoCAkJVs4nl0PQA' //new from personal

//config.apiKey = 'sk-proj-Tl6nv6o20IT8k1iklRhsT3BlbkFJXW4YboIXPVHFuOA0FcDY'  // 1 generated
config.apiKey = 'sk-svcacct-Q1ZF-DOVwNzW-w8aPog8uZxEoOLx05MIDHFzhxhZ3Fl22uLqmVHJVT3BlbkFJGYiEXBTd6elRfjWHS7JVh2qt6D18M32nqniY9lMB5oertYi8mcLAA'  // new one

module.exports = config;
