import React, { useState, useRef, useEffect } from 'react';
import Cookies from 'js-cookie';
import '../styles/App.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import ProfileMenu from './ProfileMenu.js';
import AppMenu from './AppMenu.js';
import SignUp from './SignUp.js';
import SignIn from './SignIn.js';
import About from './About.js';
import PasswordPopup from './PasswordPopup.js';
import CookieConsent from './CookieConsent.js';
import AppToolbar from './AppToolbar.js';
import ApplicationList from './ApplicationList.js';
import cdoAI from '../cdo/cdoAI.js';
import cdoSession from '../cdo/cdoSession.js';
import cdoProfile from '../cdo/cdoProfile.js';
import cdoApp from '../cdo/cdoApp.js'
import config from '../config.js';

function App() {
  const [messages, setMessages] = useState([
    { content: 'Welcome to the Software Factory.\n\nHow can I help you?', role: "assistant" }
  ]);
  const [conversationState, setConversationState] = useState('New')
  const [extractedData, setExtractedData] = useState([])
  const [passwordPopup, setPasswordPopup] = useState(0)  // 0=not visible, 1=create password, 2=supply password
  const [inputValue, setInputValue] = useState('');
  const [darkMode, setDarkMode] = useState(false);
  const [profileMenuVisible, setProfileMenuVisible] = useState(false);
  const [appMenuVisible, setAppMenuVisible] = useState(false);
  const [signUpVisible, setSignUpVisible] = useState(false);
  const [signInVisible, setSignInVisible] = useState(false);
  const [aboutVisible, setAboutVisible] = useState(false);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [session, setSession] = useState({});
  const [userData, setUserData] = useState(cdoProfile.getEmptyRow())
  const [appData, setAppData] = useState([])
  const [appConfig, setAppConfig] = useState({})
  const [cookieConsentVisible, setCookieConsentVisible] = useState(true);
  const [showApplicationList, setShowApplicationList] = useState(false)
  const inputRef = useRef(null);
  const conversationRef = useRef(null);
  const appInitiatlizedRef = useRef(false);
  const userEmailRef = useRef('')
  const latestResponseRef = useRef('')
  
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      inputRef.current.style.height = `${Math.min(inputRef.current.scrollHeight, 100)}px`;
    }
  }, [inputValue]);

  useEffect(() => {
    if (conversationRef.current) {
      conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
    }
  }, [messages,appConfig]);

  useEffect(() => {
    if (!appInitiatlizedRef.current) {
      appInitiatlizedRef.current = true;
      const cookieSessionString = Cookies.get('session')
      let storedSession = {idSession: null, cookiesEnabled: false}
      if (cookieSessionString !== undefined ) storedSession = JSON.parse(cookieSessionString)
      if (storedSession.idSession === null) {
        cdoSession.getLocation((errGeo, geoData) => {
          const sessionData = {
            idSession: '',
            ipAddress: '', 
            geolocation: '',
            browser: navigator.userAgent,
            screenWidth: window.screen.width,
            screenHeight: window.screen.height,
            referrerURL: document.referrer,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            language: navigator.language,
            cookiesEnabled: false
          };
    
          if (!errGeo && geoData) {
            sessionData.ipAddress = geoData.ip;
            sessionData.geolocation = `${geoData.city}, ${geoData.region}, ${geoData.country_name}, organization:${geoData.org}`;
          }
    
          cdoSession.create(sessionData, (err, result) => {
            console.log(err, result)
            if (!err && result && result.idEntity) {
              sessionData.idSession = result.idEntity
              if (session.cookiesEnabled) {
                Cookies.set('session', sessionData);
              }
              setSession(sessionData);
            }
          });
        });
      } else {
        console.log("storedSession")
        console.log(storedSession)
        setSession(storedSession)
        // Update the session if it already exists
        cdoSession.update(storedSession, (err, result) => {
          if (err) {
            console.error('Error updating session:', result);
          }
        });
      }
    }
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const mergeExtractedData = (prevData, newData) => {
    // Create a map from the first array to quickly lookup existing objects by dataName
    const map = new Map(prevData.map(item => [item.dataName, item]));

    // Iterate over the second array to merge new or updated objects
    newData.forEach(item => {
        if (map.has(item.dataName)) {
        // If dataName already exists, update the dataValue
            map.get(item.dataName).dataValue = item.dataValue;
        } else {
        // If dataName does not exist, add the new object
            map.set(item.dataName, item);
        }
    });

    // Convert the map back to an array
    return Array.from(map.values());
  }

  const handleResponse = (response, emailExists) => {
    let newUserData = userData
    let newExtractedData = {}
    if (response.extractedData && response.extractedData.length > 0) {
      newExtractedData = response.extractedData.reduce((accumulate, curr) => {
        accumulate[curr.dataName] = curr.dataValue;
        return accumulate;
        }, {});
    }
    let aiMessage = { content: response.factoryResponse, role: "assistant" };
    if (response.factoryAction || (response.extractedData && response.extractedData.length > 0)) {
      setConversationState( response.factoryAction )
      let alertMsg = "Factory Action: " + response.factoryAction + "\n\n"
      if (response.extractedData && response.extractedData.length > 0) {
        setExtractedData((prevData)=>mergeExtractedData(prevData,response.extractedData))
        response.extractedData.forEach( (e)=>{
          alertMsg += e.dataName + ": " + e.dataValue + "\n\n"
          if (e.dataName==="sixDigitCode" || 
              e.dataName==="alphaCode" || 
              e.dataName==="personFirstName" ||
              e.dataName==="personLastName" ||
              e.dataName==="fullName" || 
              e.dataName==='personPreferredName') 
            newUserData[e.dataName] = e.dataValue
        })
      }
      //alert(alertMsg);
      if (((response.factoryAction === "Sign In" && userEmailRef.current.length > 6) ||
          (response.factoryAction === "Register" && emailExists)) && !newUserData.idEntity) {
        newUserData.userNameOrEmail = userEmailRef.current
        setUserData( newUserData )
        setPasswordPopup(2)  //login
      }
      if (response.factoryAction === "Resend") {
        cdoProfile.resendEmail( newUserData, (err, result)=>{
          if (err) {
            let sysResponse = result.response + "\n\n" + JSON.stringify(result.error)
            aiMessage.content = sysResponse
            aiMessage.role = "error"
            setMessages((prevMessages) => [...prevMessages, aiMessage]);
          }
        })
      }
      if (response.factoryAction==="Specify") {
        let newAppConfig = {...appConfig}
        newAppConfig.messages.push(aiMessage)
        setAppConfig(newAppConfig)
      }
      if (response.factoryAction === "Load") {
        let found = false
        appData.forEach((app)=>{
          if (app.appName === newExtractedData.appName) found = app.idEntity
        })
        if (found) loadSelectedApp(found)
        else setShowApplicationList(true)
      }
      if (response.factoryAction === "Create" &&
          newExtractedData.appName && newExtractedData.appName.length > 0) {
        let newAppConfig = cdoApp.getEmptyRow()
        newAppConfig.idUser = userData.idEntity
        newAppConfig.appName = newExtractedData.appName
        setAppConfig(newAppConfig)
        aiMessage.content = `Your application ${newAppConfig.appName} is being launched...\nPlease stand by.  This may take a couple of minutes.`;
        cdoAI.launch(newAppConfig, (error, response) => {
            console.log('Response ', response);
            if (response) {
                if (!error) {
                    if (!response.error) {
                        if (response.appUrl) {
                            newAppConfig = {...newAppConfig, ...response}
                            aiMessage = {...aiMessage}
                            aiMessage.content = 'Successfully launched your application. Please use the rocket launch button above to view your application in another browser tab.  Please tell me about features you would like to create.'
                            newAppConfig.messages.push(aiMessage)
                            setAppConfig(newAppConfig)
                            setAppData(prevApp=>[...prevApp, newAppConfig])
                            setConversationState("Specify")
                        } else {
                            aiMessage = {...aiMessage}
                            aiMessage.content = 'Error: url not received from service.'
                            aiMessage.role = "error"
                            setMessages((prevMessages) => [...prevMessages, aiMessage])
                        }
                    } else {
                        aiMessage = {...aiMessage}
                        aiMessage.content = `${response.message}`
                        aiMessage.role = "error"
                        setMessages((prevMessages) => [...prevMessages, aiMessage])
                    }
                } else {
                    aiMessage = {...aiMessage}
                    aiMessage.content = 'Error ' + error + ' calling launch service. ' + response.message
                    aiMessage.role = "error"
                    setMessages((prevMessages) => [...prevMessages, aiMessage])
                }
            } else {
                aiMessage = {...aiMessage}
                aiMessage.content = 'Error receiving return data from launch service.'
                aiMessage.role = "error"
                setMessages((prevMessages) => [...prevMessages, aiMessage])
            }
        });
      }
      if ((response.factoryAction === "Register" || response.factoryAction === "Fund") && 
          newUserData.sixDigitCode && newUserData.sixDigitCode.length>0 && !newUserData.emailVerified) {
        newUserData.emailVerifyCode = newUserData.sixDigitCode
        cdoProfile.verifyEmail( newUserData, (err, result)=>{
          if (err || result.response !== 'Verified') {
            aiMessage.content = result.error
            aiMessage.role = "error"
          }
          if (!err && result.response === 'Verified') {
            newUserData.emailVerified = true
            if (newUserData.accountBalance && newUserData.accountBalance > 0) {
              aiMessage.content = "Your email account has been verified.  You can now use the factory to build software."
              loadApps( newUserData, [...messages, aiMessage])
            } else {
              setConversationState("Fund")
              aiMessage.content = "Your email account has been verified.  Have you been invited to the Alpha program with a special code?"
            }
          }
          setMessages((prevMessages) => [...prevMessages, aiMessage])
        })
        setUserData( newUserData )
      }
      else if (response.factoryAction === "Fund" || response.factoryAction === "Ready")  {
        if (newExtractedData.alphaCode && newExtractedData.alphaCode.length > 0) {
          console.log(newUserData)
          cdoProfile.useAlphaCode( newUserData, (err,response) => {
            console.log(response)
            if (!err && response.message==="Success") {
              aiMessage.content = "Your account has been successfully funded using the code: " + newUserData.alphaCode
              newUserData.accountBalance = response.accountBalance
              loadApps( newUserData, [...messages, aiMessage])
              setUserData( newUserData )
            }
            else {
              aiMessage.content = "Alpha Program Code " + response.message
              setConversationState("Fund")
            }
            setMessages((prevMessages) => [...prevMessages, aiMessage])
          })
        }
        else setMessages((prevMessages) => [...prevMessages, aiMessage])
      }
      else {
        if (response.factoryAction === "Register" && !emailExists && 
            newUserData.fullName && newUserData.fullName.length > 0 &&
            userEmailRef.current.length > 6 && !newUserData.idEntity  && newUserData.personPreferredName) {
          let accUserData = extractedData.reduce((accumulate, curr) => {
            accumulate[curr.dataName] = curr.dataValue;
            return accumulate;
          }, {});
          newUserData = {...newUserData, accUserData}
          newUserData.userName = userEmailRef.current //hard code user name to email address for now as userName is not being used
          newUserData.emailAddress = userEmailRef.current
          latestResponseRef.current = response.factoryResponse
          setUserData( newUserData )
          setPasswordPopup(1)  //create password
        }
        else {
          setMessages((prevMessages) => [...prevMessages, aiMessage]);
        }
      }
    }
    else {
      const aiMessage = { content: response.message, role: "assistant" }
      if (response.factoryAction==="Specify") {
        let newAppConfig = {...appConfig}
        newAppConfig.messages.push(aiMessage)
        setAppConfig(newAppConfig)
      }
      else setMessages((prevMessages) => [...prevMessages, aiMessage]);
    }
  }

  const passwordSubmit = (mode, password) => {
    const aiMessage = { content: '', role: "assistant" }
    let newUserData = userData
    newUserData.userPassword = password
    if (mode === 1) {     //Register
      //user just created password - call services to create entity, person, user, email, send verify email  
      cdoProfile.addProfile( newUserData, (err, result) => {
        if (!err) {
          aiMessage.content = latestResponseRef.current
          setMessages((prevMessages) => [...prevMessages, aiMessage]);
          if (result.idEntity) {
            setUserData(result)
          }
        }
      })
    }
    else if (mode ===2) {  //Sign In
      cdoProfile.signIn( newUserData, (err, result)=> {
        if(!err) {
          //set user data
          console.log("Signed In")
          console.log(result.user)
          if (result.response === "Success") {
            setUserData(result.user)
            //sessionData = {...result.session} - this is from userSession not session (current this userSession not being used)
            if (result.user.emailVerified) {
              if (result.user.accountBalance && result.user.accountBalance > 0) {
                setConversationState("Ready")
                aiMessage.content = "You have successfully logged in.  You can now use the factory to create software."
                loadApps(result.user, [...messages, aiMessage] )
              } else {
                setConversationState("Fund")
                aiMessage.content = "You have successfully logged in. \n\n Have you been invited to the Alpha Program with a special code?"
              }
            } else {
              setUserEmail(result.user.emailAddress)
              setConversationState("Register - with sub-state: Verify")
              aiMessage.content = "Your email has not been verified.  Please enter the six-digit code sent to valid@email.com or ask me to resend the email."
            }
            setMessages((prevMessages) => [...prevMessages, aiMessage])
          }
          else {
            aiMessage.content = "Your sign in attempt was unsuccessful with the credentials provided."
            setMessages((prevMessages) => [...prevMessages, aiMessage])
            setTimeout( setPasswordPopup, 2000, 2)
          }
        }
      } )
    }
  }
  
  const loadApps = (currentUserData, currentMessages ) => {
    let recentAppLoad = []
    setConversationState("Ready")
    cdoApp.loadAppsByUser({idEntity: currentUserData.idEntity},(err, result)=>{
      console.log(currentUserData.idEntity)
      console.log(result)
      if (!err) {
        recentAppLoad = result
        setAppData(result)
      }
      currentUserData.emailAddress = userEmailRef.current
      let data = { apiKey: config.apiKey, model: "gpt-4o", messages: [...currentMessages, {content: "What's next?", role: "user"}], conversationState: "Ready", extractedData, idSession: session.idSession, emailExists: true, userData: currentUserData, appData: recentAppLoad }
      cdoAI.ask(data, (err, response) => {
        if (!err) {
          handleResponse(response, true)
        }
        else {
          const aiMessage = { content: JSON.stringify(result), role: "assistant" };
          setMessages((prevMessages) => [...prevMessages, aiMessage]);
        }
        setIsInputDisabled(false);
      });
    })
  }

  const loadSelectedApp = (idEntity) => {
    cdoApp.loadApp({idEntity}, (error, result)=>{
      if (!error) {
        console.log(result)
        setAppConfig( {...cdoApp.getEmptyRow(), ...result} )
        setConversationState("Specify")
      }
      else alert("Error loading application.")
    })
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  const setUserEmail = (emailAddress) => {
    userEmailRef.current = emailAddress
  }

  const hideEmail = (inputString) => {
    // Regular expression to match valid email addresses
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;
  
    // Use replace method with a callback function to handle extraction and replacement
    const resultString = inputString.replace(emailRegex, (matchedEmail) => {
      // Call the provided function with the extracted valid email
      userEmailRef.current = matchedEmail;
      
      // Replace the matched email with "valid@email.com"
      return "valid@email.com";
    });
  
    return resultString;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputValue.trim()) {
      const userMessage = { content: hideEmail(inputValue), role: "user" };
      let newUserData = {...userData, emailAddress: userEmailRef.current}
      let data = { apiKey: config.apiKey, model: "gpt-4o", messages: [...messages, userMessage], conversationState, extractedData, idSession: session.idSession, emailExists: null, userData: newUserData, appData }
      if (conversationState==="Specify") {
        let newAppConfig = appConfig
        newAppConfig.messages.push(userMessage)        
        setAppConfig(newAppConfig);
        data.messages = newAppConfig.messages
        data.appData = newAppConfig
      }
      else {
        setMessages([...messages, userMessage]);
      }
      setIsInputDisabled(true);
      // if we are in the New state and have been given an email, check to see if it is for an existing user
      if ((conversationState==="New" || conversationState ==="Register" ) && userEmailRef.current.length > 5) 
        cdoProfile.validateUserEmail( {emailAddress: userEmailRef.current}, (err, response) => {
          if (!err) {
            if (response.message === "Exists") data.emailExists = true
            else data.emailExists = false
            handleAsk(data)
          }
      })
      else handleAsk(data)
      setInputValue('');
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  const handleAsk = (data) => {
    cdoAI.ask(data, (err, response) => {
      if (!err) {
        handleResponse(response, data.emailExists)
      }
      else {
        const aiMessage = { content: JSON.stringify(response), role: "assistant" };
        if (conversationState==="Specify") {
          let newAppConfig = appConfig
          newAppConfig.messages.push(aiMessage)
          setAppConfig(newAppConfig)
        }
        else setMessages((prevMessages) => [...prevMessages, aiMessage]);
      }
      setIsInputDisabled(false);
    });
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const toggleProfileMenu = () => {
    setProfileMenuVisible(!profileMenuVisible);
  };

  const toggleAppMenu = () => {
    setAppMenuVisible(!appMenuVisible);
  };

  const toggleAbout = (show) => {
    setAboutVisible(show);
    setAppMenuVisible(false); // Ensure the app menu is hidden when About is toggled
  };

  const handleAcceptCookies = () => {
    let newSession = { ...session, cookiesEnabled: true }
    setSession(newSession);
    setCookieConsentVisible(false)
    Cookies.set('session', JSON.stringify(newSession));
  };

  const handleDeclineCookies = () => {
    setSession(({ ...session, cookiesEnabled: false }));
    setCookieConsentVisible(false)
  };

  const handleRefactorResponse = (error, response) => {
    let newAppConfig = {...appConfig, generatingCode: false}
    let aiMessage = {role:"error", content: ""}
    if (!error) {
        if (response.message === 'Success' || response.fixErrors) {
            let updatedFileCount = 0
            if (response.files && response.files.length) updatedFileCount = response.files.length
            newAppConfig = {...appConfig, ...response, generatingCode: false}
            aiMessage.content = response.systemPrompt;
            if (!response.fixErrors) aiMessage.role = "success"       
        } else aiMessage.content = response.message
    } else {
        aiMessage.content = `Error: ${error}; ${response.message}`
    }
    newAppConfig.messages.push(aiMessage)
    setAppConfig(newAppConfig)
  }

  const handleUndoResponse = (error, response) => {
    let newAppConfig = {...appConfig, generatingCode: false}
    let aiMessage = {role:"error", content: ""}
    if (!error && !response.error) {
        aiMessage.content = `The undo of the last code changes to the application was successful.`
        aiMessage.role = "success"  
    } else {
        aiMessage.content = response.error || "Error calling Undo action."
    }
    newAppConfig.messages.push(aiMessage)
    setAppConfig(newAppConfig)
  }

  const toolbarSubmit = (action) => {
    let newMsg = false
    switch (action) {
      case "Launch":
        var handle = window.open(appConfig.appUrl, '_blank', 'noopener,noreferrer');
        if (handle) {
          setTimeout(() => window.focus(), 0); // Asynchronously refocuses the current window
        }
        break
      case "Images":
        alert("Images will becoming incredibly soon.")
        break
      case "Requirements":
        alert("This is not available in the base Alpha program.")
        break
      case "Design":
        alert("This is not available in the base Alpha program.")
        break
      case "Generate":
        newMsg = "The factory is generating changes to the application based on our conversation.  Please stand by, these changes may take a few minutes to complete..."
      case "Fix Errors":
          if (!newMsg) newMsg = "The factory is attempting to fix any outstanding errors in the application.  Please stand by, these changes may take a few minutes to complete..."
          let newAppConfig = {...appConfig, action, generatingCode: true}
          newAppConfig.messages.push({role: "assistant", content: newMsg})
          setAppConfig(newAppConfig)
          cdoAI.refactor(newAppConfig, handleRefactorResponse)
        break
      case "Undo":
        let newAppConfigUndo = {...appConfig, action, generatingCode: true}
        newAppConfig.messages.push({role: "assistant", content: "Attempting to undo the last changes made to the application..."})
        setAppConfig(newAppConfigUndo)
        cdoAI.undo(newAppConfig, handleUndoResponse)
        break
      case "Credentials":
        alert("Providing credentials to connect to external services will be coming soon.")
        break
      case "Settings":
        alert("This is not available in the base Alpha program.")
        break
      default:
        alert("This is not available in the base Alpha program.")
    }
  };

  const handleSelectApp = (app) => {
    loadSelectedApp(app.idEntity);
    setShowApplicationList(false);
  };

  const handleCancelSelectApp = () => {
    setShowApplicationList(false);
  };

  return (
    <div className={`App ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <header className="App-header">
        <i className="bi bi-list hamburger-icon" onClick={toggleAppMenu}></i>
        <div className="App-title-container">
          <h1 className="App-title">Incrementum</h1>
          <h1 className="App-subtitle">Alpha</h1>
        </div>
        {userData.accountBalance ? (
          <div className="user-info">
            <div className="user-balance">
              <div className="balance-label">Balance</div>
              <div className="balance-value">{userData.accountBalance.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
            </div>
            <div className="profile-circle" onClick={toggleProfileMenu}>
              {userData.personFirstName.charAt(0)}{userData.personLastName.charAt(0)}
            </div>
          </div>
        ) : (
          <i className="bi bi-person profile-icon" onClick={toggleProfileMenu}></i>
        )}
      </header>
      {conversationState === 'Specify' && <AppToolbar appConfig={appConfig} darkMode={darkMode} toolbarSubmit={toolbarSubmit}/>}      {profileMenuVisible && <ProfileMenu darkMode={darkMode} toggleDarkMode={toggleDarkMode} setProfileMenuVisible={setProfileMenuVisible} setSignUpVisible={setSignUpVisible} setSignInVisible={setSignInVisible} authorizedUser={userData.idEntity} />}
      {appMenuVisible && <AppMenu darkMode={darkMode} setAppMenuVisible={setAppMenuVisible} toggleAbout={toggleAbout} />}
      {signUpVisible && <SignUp setSignUpVisible={setSignUpVisible} darkMode={darkMode} />}
      {signInVisible && <SignIn setSignInVisible={setSignInVisible} darkMode={darkMode} />}
      {aboutVisible && <About darkMode={darkMode} toggleAbout={toggleAbout} />}
      {passwordPopup !== 0 && <PasswordPopup passwordPopup={passwordPopup} setPasswordPopup={setPasswordPopup} darkMode={darkMode} userEmailRef={userEmailRef} passwordSubmit={passwordSubmit}/>}      {showApplicationList && <ApplicationList appData={appData} darkMode={darkMode} onSelectApp={handleSelectApp} onCancel={handleCancelSelectApp} />}      {!aboutVisible && !showApplicationList && (
        <div className="App-body">
          {(conversationState==="Specify") ?
            <div className="App-conversation" ref={conversationRef}>
              {appConfig.messages.map((msg, index) => (
                <div
                  key={index}
                  className={`App-message ${(msg.role==="user") ? 'user-message' : (msg.role === "success") ? 'success-message' : (msg.role === "error") ? 'error-message' : 'app-message'}`}
                >
                  {msg.content.split('\n').map((line, i) => (
                    <React.Fragment key={i}>
                      {line.replace("valid@email.com",userEmailRef.current)}
                      <br />
                    </React.Fragment>
                  ))}
                </div>
              ))}
            </div>
          : 
            <div className="App-conversation" ref={conversationRef}>
              {messages.map((msg, index) => (
                <div
                  key={index}
                  className={`App-message ${(msg.role==="user") ? 'user-message' : (msg.role === "success") ? 'success-message' : (msg.role === "error") ? 'error-message' : 'app-message'}`}
                >
                  {msg.content.split('\n').map((line, i) => (
                    <React.Fragment key={i}>
                      {line.replace("valid@email.com",userEmailRef.current)}
                      <br />
                    </React.Fragment>
                  ))}
                </div>
              ))}
            </div>
          }
          <form className="App-input" onSubmit={handleSubmit}>
            <textarea
              ref={inputRef}
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Type your message..."
              rows={1}
              className="App-textarea"
            />
            <button type="submit" disabled={isInputDisabled} className="app-submit-button"><i className="bi bi-arrow-up"></i></button>
          </form>
        </div>
      )}
      {!session.cookiesEnabled && cookieConsentVisible && <CookieConsent darkMode={darkMode} onAccept={handleAcceptCookies} onDecline={handleDeclineCookies} />}
      <footer className="App-footer">
        Incrementum Alpha v0.4.1, &copy; 1998-2024 SoftwareFactory.ai
      </footer>
    </div>
  );
}

export default App;
