import React from 'react';
import '../styles/AppToolbar.css';

function AppToolbar({ appConfig, darkMode, toolbarSubmit }) {

  // Define toolbar buttons with actions and tooltips
  const toolbarButtons = {
    launchButton: {
      class: "bi-rocket-takeoff",
      action: () => toolbarSubmit("Launch"),
      title: "Launch",
      enabled: true
    },
    imagesButton: {
      class: "bi-images",
      action: () => toolbarSubmit("Images"),
      title: "Images",
      enabled: true
    },
    requirementsButton: {
      class: "bi-journal-text",
      action: () => toolbarSubmit("Requirements"),
      title: "Requirements",
      enabled: true
    },
    designButton: {
      class: "bi-stack",
      action: () => toolbarSubmit("Design"),
      title: "Design",
      enabled: true
    },
    generateButton: {
      class: "bi-lightning",
      action: () => toolbarSubmit("Generate"),
      title: "Generate",
      enabled: true
    },
    undoButton: {
      class: "bi-arrow-counterclockwise",
      action: () => toolbarSubmit("Undo"),
      title: "Undo",
      enabled: true
    },
    fixErrorsButton: {
      class: "bi-tools",
      action: () => toolbarSubmit("Fix Errors"),
      title: "Fix Errors",
      enabled: true
    },
    credentialsButton: {
      class: "bi-key",
      action: () => toolbarSubmit("Credentials"),
      title: "Credentials",
      enabled: true
    },
    settingsButton: {
      class: "bi-gear",
      action: () => toolbarSubmit("Settings"),
      title: "Settings",
      enabled: true
    }
  };

  if (appConfig.generatingCode) toolbarButtons.generateButton.enabled = false
  if (!appConfig.fixErrors) toolbarButtons.fixErrorsButton.enabled = false

  // Corrected div with proper className syntax
return (
  <div className={`AppToolbar ${darkMode ? 'dark-mode' : 'light-mode'}`}>
    <div className="AppToolbar-content">
      <h2>{appConfig.appName}</h2>
      <div className="AppToolbar-buttons">
        {Object.keys(toolbarButtons).map((buttonKey) => {
          const { class: iconClass, action, title, enabled } = toolbarButtons[buttonKey];
          return (
            <button
              key={buttonKey}
              className="icon-button"
              onClick={action}
              title={title}
              disabled={!enabled}
            >
              <i className={`bi ${iconClass}`}></i>
            </button>
          );
        })}
      </div>
    </div>
  </div>
);

}

export default AppToolbar;
