import React, { useEffect } from 'react';
import '../styles/SignUp.css';

function SignUp({ setSignUpVisible, darkMode }) {
  const handleClose = () => {
    setSignUpVisible(false);
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest('.SignUp-content')) {
      setSignUpVisible(false);
    }
  };

  const handleButtonClick = () => {
    alert('Feature coming soon, please register conversationally with Incrementum.');
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="SignUp-modal">
      <div className={`SignUp-content ${darkMode ? 'dark-mode' : 'light-mode'}`}> {/* Apply dark/light mode */}
        <button className="SignUp-close" onClick={handleClose}>&times;</button>
        <h2>Sign Up</h2>
        <div className="SignUp-buttons">
          <button className={`SignUp-button google ${darkMode ? 'dark-mode' : 'light-mode'}`} onClick={handleButtonClick}><i className="bi bi-google"></i> Google</button>
          <button className={`SignUp-button microsoft ${darkMode ? 'dark-mode' : 'light-mode'}`} onClick={handleButtonClick}><i className="bi bi-microsoft"></i> Microsoft</button>
          <button className={`SignUp-button apple ${darkMode ? 'dark-mode' : 'light-mode'}`} onClick={handleButtonClick}><i className="bi bi-apple"></i> Apple</button>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
