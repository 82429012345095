import React from 'react';
import '../styles/About.css';

function About({ darkMode, toggleAbout }) {
  return (
    <div className={`About ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <div className="About-header">
        <button className="About-close" onClick={() => toggleAbout(false)}>&times;</button>
      </div>
      <div className="About-content">
        <h1>About Incrementum Alpha</h1>
        <p>Welcome to Incrementum Alpha, a pioneering initiative from SoftwareFactory.ai. Our mission is to revolutionize how software is created, making the development process as intuitive as having a conversation. Incrementum Alpha embodies the first step towards a future where anyone can bring their software ideas to life without needing to write a single line of code.</p>

        <h2>Vision and Innovation</h2>
        <p>Incrementum Alpha, brought to you by the visionary minds of Carson Holmes and Mark Kennaley, emerges as a beacon of innovation in software development. This project, backed by over twenty-five years of research and development, leverages advanced AI and generative technologies to transform user inputs into functional web applications. Our platform is designed to understand and interpret human language, enabling users to directly communicate their software needs and see them materialize in real-time.</p>

        <h2>How It Works</h2>
        <p>Here at SoftwareFactory.ai, we’ve developed a unique process that breaks down traditional barriers in software development. Users start by describing their desired application. Incrementum then iterates on this vision, rapidly generating and refining the software based on user feedback. This iterative process continues until the product meets the user's specified needs.</p>

        <h2>Special Features</h2>
        <ul>
            <li><strong>User-Centric Design</strong>: Tailored to accommodate users without any technical background.</li>
            <li><strong>Real-Time Preview</strong>: Changes are visible instantaneously, providing a dynamic development experience.</li>
            <li><strong>Iterative Feedback Loop</strong>: Continuous refinement based on user feedback.</li>
            <li><strong>Exclusive Access</strong>: By invite only, ensuring personalized attention and support.</li>
        </ul>

        <h2>Security and Compliance</h2>
        <p>We prioritize the security of your data and the confidentiality of your projects. While Incrementum Alpha is not yet GDPR compliant, we are committed to achieving compliance in future versions. Please note, the applications generated during the Alpha phase are not secure and should not be used for production environments or involve sensitive data in any way.  Securing the generated applications can be done, but currently this isn't automated through the factory.</p>

        <h2>Join the Alpha Program</h2>
        <p>Incrementum Alpha is currently available by invite only.  If you have a passion for innovation and are eager to explore the frontiers of software development, please register and explain to Incrementum why you and/or your organization should be included in the Alpha Program. Your participation and feedback will play a crucial role in shaping the future of this technology.</p>

        <h2>Future Roadmap</h2>
        <p>We are continually evolving and expanding the capabilities of Incrementum Alpha. While currently focused on web applications, future updates will include support for mobile platforms and more complex integrations. Stay tuned for announcements on our progression from Alpha to Beta phases and beyond.</p>

        <h2>Contact Us</h2>
        <p>First and foremost, you can contact us using Incrememtum.  All conversations are recorded and analyzed by Incrememtum and all feedback and questions are communicated to the appropriate humans.
            If you want to contact a human, please reach out to <a href="mailto:support@softwarefactory.ai">support@softwarefactory.ai</a>. Your input is invaluable to us, and we look forward to hearing from you.</p>

        <p><strong>Embark on a Journey of Software Innovation</strong><br/>Join us in redefining the landscape of software development. With Incrementum Alpha, your journey from concept to application is just a conversation away.</p>
      </div>
    </div>
  );
}

export default About;
