import React, { useEffect } from 'react';
import '../styles/ProfileMenu.css';

function ProfileMenu({ darkMode, toggleDarkMode, setProfileMenuVisible, setSignUpVisible, setSignInVisible, authorizedUser }) {

  const handleMenuClick = () => {
    // Hide the menu after any option is clicked
    setProfileMenuVisible(false);
  };

  const handleSignUpClick = () => {
    setSignUpVisible(true);
    setProfileMenuVisible(false);
  };

  const handleSignInClick = () => {
    setSignInVisible(true);
    setProfileMenuVisible(false);
  };

  const handleSignOutClick = () => {
    // Logic for signing out the user
    console.log('User signed out');
    setProfileMenuVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.ProfileMenu') && !event.target.closest('.profile-icon')) {
        setProfileMenuVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setProfileMenuVisible]);

  return (
    <div className={`ProfileMenu ${darkMode ? 'dark-mode' : 'light-mode'}`} onClick={handleMenuClick}>
      {!authorizedUser && (
        <div className="ProfileMenu-item" onClick={handleSignInClick}>
          <i className="bi bi-box-arrow-in-right"></i>
          <span>Sign In</span>
        </div>
      )}
      {!authorizedUser && (
        <div className="ProfileMenu-item" onClick={handleSignUpClick}>
          <i className="bi bi-person-plus"></i>
          <span>Sign Up</span>
        </div>
      )}
      {authorizedUser && (
        <div className="ProfileMenu-item">
          <i className="bi bi-person"></i>
          <span>Profile</span>
        </div>
      )}
      <div className="ProfileMenu-item" onClick={toggleDarkMode}>
        <i className={`bi ${darkMode ? 'bi-sun' : 'bi-moon'}`}></i>
        <span>{darkMode ? 'Light Mode' : 'Dark Mode'}</span>
      </div>
      {authorizedUser && (
        <>
          <hr className="ProfileMenu-divider" />
          <div className="ProfileMenu-item" onClick={handleSignOutClick}>
            <i className="bi bi-box-arrow-right"></i>
            <span>Sign Out</span>
          </div>
        </>
      )}
    </div>
  );
}

export default ProfileMenu;
