import React, { useEffect } from 'react';
import '../styles/AppMenu.css';

function AppMenu({ darkMode, setAppMenuVisible, toggleAbout }) {
  const handleMenuClick = (menuOption) => {
    if (menuOption === 'home') {
      toggleAbout(false);
    } else if (menuOption === 'about') {
      toggleAbout(true);
    }
    setAppMenuVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.AppMenu') && !event.target.closest('.hamburger-icon')) {
        setAppMenuVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setAppMenuVisible]);

  return (
    <div className={`AppMenu ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <div className="AppMenu-item" onClick={() => handleMenuClick('home')}>
        <i className="bi bi-house"></i>
        <span>Home</span>
      </div>
      <div className="AppMenu-item" onClick={() => handleMenuClick('about')}>
        <i className="bi bi-info-circle"></i>
        <span>About</span>
      </div>
    </div>
  );
}

export default AppMenu;
