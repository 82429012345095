import util from '../shared/util.js';
import config from '../config.js';

var servicesURL = `${config.server.url}:${config.server.webServicesPort}/app/`;

class cdoApp {
    addApp = (data, callback) => {
        util.putJSON(servicesURL + 'add', data, callback);
    }

    loadApp = (data, callback) => {
        util.putJSON(servicesURL + 'load', data, callback);
    }

    loadAppByCode = (data, callback) => {
        util.putJSON(servicesURL + 'load-by-code', data, callback);
    }

    loadAppsByUser = (data, callback) => {
        util.putJSON(servicesURL + 'load-by-user', data, callback);
    }

    updateApp = (data, callback) => {
        util.putJSON(servicesURL + 'update', data, callback);
    }

    deleteApp = (data, callback) => {
        util.putJSON(servicesURL + 'delete', data, callback);
    }

    getEmptyRow = () => {
        const newRow = {
            idEntity: null, //id of app
            idUser: '',
            rootDirectory: "c:\\dev\\factory\\template",
            appName: "",
            appUrl: '',
            cloneUrl: '',
            version: 0,
            messages: [],
            imageFiles: [],
            newImageFiles: [],
            removeImageFiles: [],
            goals: "",
            technology: "React-Node-Express-MySQL",
            model: "gpt-4o",
            apiKey: config.apiKey,
            additionalInstructions: "",
            filePaths: [
                { path: "client\\src\\cdo", selected: true },
                { path: "client\\src\\components", selected: true },
                { path: "client\\src\\styles", selected: true },
                { path: "server\\BPL", selected: true },
                { path: "server\\DAL", selected: true },
                { path: 'server\\routes', selected: true }
            ],
            fixErrors: false,
            errorText: '',
            generatingCode: false,    
            timezoneOffset: new Date().getTimezoneOffset(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        return newRow;
    }
}

const cdo = new cdoApp();
export default cdo;