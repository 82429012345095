import React, { useState, useEffect, useRef } from 'react';
import '../styles/PasswordPopup.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

function PasswordPopup({ passwordPopup, setPasswordPopup, darkMode, userEmailRef, passwordSubmit }) {
  const [password, setPasswordState] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [criteria, setCriteria] = useState({
    length: false,
    number: false,
    specialChar: false,
    match: false
  });
  const passwordRef = useRef()

  useEffect(() => {
    const length = password.length >= 8;
    const number = /\d/.test(password);
    const specialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const match = password === confirmPassword;
    setCriteria({ length, number, specialChar, match });
  }, [password, confirmPassword]);

  useEffect(()=>{
    if (passwordRef.current) {
      passwordRef.current.focus();
    }
  }, [])

  const handlePasswordChange = (e) => {
    setPasswordState(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleShowPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleOkClick = () => {
    passwordSubmit(passwordPopup, password)
    setPasswordPopup(0);
  };

  const handleCancelClick = () => {
    setPasswordPopup(0);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleOkClick();
    }
  };

  return (
    <div className={`PasswordPopup ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <div className="PasswordPopup-content">
        {passwordPopup === 1 && (
          <>
            <h3>Create Password</h3>
            <div className="PasswordPopup-criteria">
              <p>Minimum requirements:</p>
              <ul>
                <li className={criteria.length ? 'valid' : ''}><i className={`bi ${criteria.length ? 'bi-check' : 'bi-x'}`}></i> 8 characters in length</li>
                <li className={criteria.number ? 'valid' : ''}><i className={`bi ${criteria.number ? 'bi-check' : 'bi-x'}`}></i> 1 number character</li>
                <li className={criteria.specialChar ? 'valid' : ''}><i className={`bi ${criteria.specialChar ? 'bi-check' : 'bi-x'}`}></i> 1 special character</li>
                <li className={criteria.match ? 'valid' : ''}><i className={`bi ${criteria.match ? 'bi-check' : 'bi-x'}`}></i> confirmation password matches</li>
              </ul>
            </div>
          </>
        )}
        {passwordPopup === 2 && (
          <div className="PasswordPopup-title">
            <p>Sign In for {userEmailRef.current}</p>
          </div>
        )}
        <div className="PasswordPopup-inputs">
          <input
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handlePasswordChange}
            onKeyDown={handleKeyDown}
            placeholder="Enter password"
            ref={passwordRef}
          />
          {passwordPopup === 1 && (
            <input
              type={showPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              onKeyDown={handleKeyDown}
              placeholder="Confirm password"
            />
          )}
          <button onClick={handleShowPasswordToggle} className="show-password-toggle">
            {showPassword ? 'Hide' : 'Show'} {(passwordPopup === 1) ? 'Passwords' : 'Password'}
          </button>
        </div>
        <div className="PasswordPopup-buttons">
          <button onClick={handleCancelClick} className="cancel-button">
            <i className="bi bi-x"></i> Cancel
          </button>
          <button
            onClick={handleOkClick}
            className="ok-button"
            disabled={passwordPopup === 1 && !(criteria.length && criteria.number && criteria.specialChar && criteria.match)}
          >
            <i className="bi bi-check"></i> Ok
          </button>
        </div>
      </div>
    </div>
  );
}

export default PasswordPopup;
