import React, { useState } from 'react';
import '../styles/ApplicationList.css';

function ApplicationList({ appData, darkMode, onSelectApp, onCancel }) {
  const [selectedApp, setSelectedApp] = useState(null);

  const handleRowClick = (app) => {
    setSelectedApp(app);
  };

  const handleOkClick = () => {
    if (selectedApp) {
      onSelectApp(selectedApp);
    }
  };

  return (
    <div className={`ApplicationList ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <div className="ApplicationList-header">
        <h2>Select an Application</h2>
      </div>
      <div className="ApplicationList-content">
        <table>
          <thead>
            <tr>
              <th>App Name</th>
              <th>Last Updated</th>
            </tr>
          </thead>
          <tbody>
            {appData.map((app) => (
              <tr 
                key={app.idEntity} 
                className={selectedApp && selectedApp.idEntity === app.idEntity ? 'selected' : ''}
                onClick={() => handleRowClick(app)}
              >
                <td>{app.appName}</td>
                <td>{new Date(app.dtUpdated).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="ApplicationList-buttons">
        <button onClick={handleOkClick} disabled={!selectedApp} className="ok-button">
          <i className="bi bi-check"></i> Ok
        </button>
        <button onClick={onCancel} className="cancel-button">
          <i className="bi bi-x"></i> Cancel
        </button>
      </div>
    </div>
  );
}

export default ApplicationList;
